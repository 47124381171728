import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Bouton';

const Calendar = () => {
  const [recentEvents, setRecentEvents] = useState([]);

  useEffect(() => {
    fetch('https://alpine-larochelle.com/api/events')
      .then((response) => response.json())
      .then((data) => {
        console.log('All items:', data); // Debug
        const eventsWithDates = data
          .sort((a, b) => new Date(a.date) - new Date(b.date))
        console.log('Filtered events:', eventsWithDates); // Debug
        setRecentEvents(eventsWithDates);
      })
      .catch((err) => console.log('Error loading events:', err));
  }, []);

  return (
    <section className="calendar">
      <div className="calendar__title">
        <h2>Les prochains événements à ne pas manquer</h2>
      </div>
      <div className="calendar__content">
        <ul className="calendar__events">
          {recentEvents.map(event => (
            <li key={event._id} className="calendar__event">
              <span className="calendar__event__date">
                {event.date && new Date(event.date).toLocaleDateString('fr-FR', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </span>
              <span className="calendar__event__separator">-</span>
              <span className="calendar__event__name">{event.title}</span>
              <div className="calendar__event__info">{event.text}</div>
              <div className="calendar__event__link">
                <Button Link={event.link} Text="Lien d'inscription" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Calendar;