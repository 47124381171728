import React, { useState, useEffect } from 'react';
import Button from './Bouton';

export default function AddEvents({ setEventsList, onBack, eventToEdit = null }) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    if (eventToEdit) {
      setTitle(eventToEdit.title);
      setText(eventToEdit.text);
      setLink(eventToEdit.link || '');
      setDate(eventToEdit.date.split('T')[0]);
    }
  }, [eventToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const eventData = {
      title,
      text,
      link,
      date
    };

    const url = eventToEdit 
      ? `https://alpine-larochelle.com/api/events/${eventToEdit._id}`
      : 'https://alpine-larochelle.com/api/events';

    fetch(url, {
      method: eventToEdit ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`Erreur serveur: ${data.message}`);
        }
        return data;
      })
      .then((data) => {
        if (eventToEdit) {
          setEventsList(prevEvents => 
            prevEvents.map(ev => ev._id === eventToEdit._id ? data : ev)
          );
        } else {
          setEventsList(prevEvents => [...prevEvents, data]);
        }
        // Reset form
        setTitle('');
        setText('');
        setLink('');
        setDate('');
        onBack();
      })
      .catch((err) => {
        console.error('Error saving event:', err);
        alert('Erreur lors de la sauvegarde: ' + err.message);
      });
  };

  return (
    <div className="add-events-container">
      <Button 
        Text="Retour" 
        Link="#" 
        onClick={onBack}
      />
      <form onSubmit={handleSubmit} className="add-events-form">
        <h2>{eventToEdit ? "Modifier l'événement" : "Ajouter un événement"}</h2>
        
        <div>
          <label>Titre de l'événement</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Informations</label>
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
            rows={10}
            style={{ width: '100%', padding: '8px' }}
          />
        </div>

        <div>
          <label>Date de l'événement</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Lien</label>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="google.com"
          />
        </div>

        <Button 
          Text={eventToEdit ? "Modifier l'événement" : "Ajouter l'événement"}
          type="submit"
        />
      </form>
    </div>
  );
}
