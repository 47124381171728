import "../styles/main.scss"
import React from 'react';
import Nav from "../components/Nav";
import Carrousel from "../components/Carrousel";
import alpineLogoLettres from "../assets/alpine-logo-lettres.png";
import NewsCarrousel from "../components/NewsCarrousel";
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <>
            <header>
                <Nav />
                <div className="alpine-logo--la-rochelle">
                    <img src={alpineLogoLettres} alt="Logo Alpine Blanc"></img>
                    <h1>Store La Rochelle</h1>
                </div>
            </header>
            <main>
                <Carrousel />
                <div className="home__calendar">
                    <div className="home__calendar__title">
                        <h2>Découvrez notre calendrier des événements à venir</h2>
                    </div>
                    <div className="home__calendar__link">
                        <Link to="/calendar">Voir le calendrier</Link>
                    </div>
                </div>
                <NewsCarrousel />
            </main>
        </>
    );
}