import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Button from './Bouton';

export default function AddNews({ setNewsList, onBack, newsToEdit = null }) {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (newsToEdit) {
      setTitle(newsToEdit.title);
      setText(newsToEdit.text);
      setLink(newsToEdit.link || '');
    }
  }, [newsToEdit]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    
    if (image) {
      formData.append('image', image);
    }
    
    if (link) {
      let validLink = link;
      if (!/^https?:\/\//i.test(link)) {
        validLink = `http://${link}`;
      }
      formData.append('link', validLink);
    }

    const url = newsToEdit 
      ? `https://alpine-larochelle.com/api/news/${newsToEdit._id}`
      : 'https://alpine-larochelle.com/api/news';

    fetch(url, {
      method: newsToEdit ? 'PUT' : 'POST',
      body: formData,
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`Erreur serveur: ${data.message}`);
        }
        return data;
      })
      .then((data) => {
        if (newsToEdit) {
          setNewsList(prevNews => 
            prevNews.map(news => news._id === newsToEdit._id ? data : news)
          );
        } else {
          setNewsList(prevNews => [...prevNews, data]);
        }
        // Reset form
        setTitle('');
        setText('');
        setLink('');
        setImage(null);
        onBack();
      })
      .catch((err) => {
        console.error('Error saving news:', err);
        alert('Erreur lors de la sauvegarde: ' + err.message);
      });
  };

  return (
    <>
      <Button 
        Text="Retour" 
        Link="#" 
        onClick={onBack}
      />
      <form onSubmit={handleSubmit} className="add-news-form">
        <h2>{newsToEdit ? "Modifier l'actualité" : "Ajouter une actualité"}</h2>
        
        <div>
          <label>Titre</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Texte</label>
          <Editor
            apiKey='7inxkbdnk1pxuxacmy164kxbff9imcxkui32pt05canei4t3'
            init={{
              height: 500,
              menubar: false,
              plugins: 'link image code table lists',
              toolbar: 'undo redo | formatselect | bold italic | ' +
                      'alignleft aligncenter alignright | ' +
                      'bullist numlist | link image | code',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              branding: false,
              promotion: false
            }}
            value={text}
            onEditorChange={(content) => setText(content)}
          />
        </div>

        <div>
          <label>Image {newsToEdit && "(laisser vide pour conserver l'image actuelle)"}</label>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            accept="image/*"
          />
        </div>

        <div>
          <label>Lien</label>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="https://..."
          />
        </div>

        <Button 
          Text={newsToEdit ? "Modifier l'actualité" : "Ajouter l'actualité"}
          type="submit"
        />
      </form>
    </>
  );
}