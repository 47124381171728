import React, { useState, useEffect } from 'react';
import Login from '../components/Login';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Bouton';
import AddNews from '../components/AddNews';
import AddEvents from '../components/AddEvents';

export default function Admin() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAddNews, setShowAddNews] = useState(false);
  const [showAddEvents, setShowAddEvents] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [editingEvent, setEditingEvent] = useState(null);
  const [editingNews, setEditingNews] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const authStatus = localStorage.getItem('isAdminAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // Charger les actualités
      fetch('https://alpine-larochelle.com/api/news')
        .then((response) => response.json())
        .then((data) => setNewsList(data))
        .catch((err) => console.log('Error loading news:', err));

      // Charger les événements
      fetch('https://alpine-larochelle.com/api/events')
        .then((response) => response.json())
        .then((data) => setEventsList(data))
        .catch((err) => console.log('Error loading events:', err));
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.removeItem('isAdminAuthenticated');
    setIsAuthenticated(false);
    navigate('/');
  };

  const handleDeleteNews = (newsId) => {
    fetch(`https://alpine-larochelle.com/api/news/${newsId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la suppression');
        }
        setNewsList(prevNews => prevNews.filter(news => news._id !== newsId));
      })
      .catch(err => {
        console.error('Error deleting news:', err);
        alert('Erreur lors de la suppression: ' + err.message);
      });
  };

  const handleDeleteEvent = (eventId) => {
    fetch(`https://alpine-larochelle.com/api/events/${eventId}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la suppression');
        }
        setEventsList(prevEvents => prevEvents.filter(event => event._id !== eventId));
      })
      .catch(err => {
        console.error('Error deleting event:', err);
        alert('Erreur lors de la suppression: ' + err.message);
      });
  };

  if (!isAuthenticated) {
    return <Login onLogin={setIsAuthenticated} />;
  }

  if (showAddNews || editingNews) {
    return <AddNews 
      setNewsList={setNewsList} 
      onBack={() => {
        setShowAddNews(false);
        setEditingNews(null);
      }}
      newsToEdit={editingNews}
    />;
  }

  if (showAddEvents || editingEvent) {
    return <AddEvents 
      setEventsList={setEventsList} 
      onBack={() => {
        setShowAddEvents(false);
        setEditingEvent(null);
      }}
      eventToEdit={editingEvent}
    />;
  }

  return (
    <div className='admin'>
      <div className="admin__header">
        <Button 
          Text="Ajouter une actualité" 
          Link="#" 
          onClick={() => setShowAddNews(true)} 
        />
        <Button 
          Text="Ajouter un événement" 
          Link="#" 
          onClick={() => setShowAddEvents(true)} 
        />
        <Button 
          Text="Déconnexion" 
          Link="#" 
          onClick={handleLogout} 
        />
      </div>

      <div className="admin__lists">
        <div className="admin__news-list">
          <h2>Liste des actualités</h2>
          <ul>
            {newsList.map((news) => (
              <li key={news._id} className="admin-news">
                <h3>{news.title}</h3>
                {news.image && (
                  <div className="admin-news__image">
                    <img src={`https://alpine-larochelle.com/uploads/${news.image}`} alt={news.title} />
                  </div>
                )}
                <div className="admin-news__buttons">
                  <Button 
                    Text="Modifier" 
                    Link="#" 
                    onClick={() => setEditingNews(news)}
                  />
                  <Button 
                    Text="Supprimer" 
                    Link="#" 
                    onClick={() => handleDeleteNews(news._id)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="admin__events-list">
          <h2>Liste des événements</h2>
          <ul>
            {eventsList.map((event) => (
              <li key={event._id} className="admin-event">
                <h3>{event.title}</h3>
                <p className="admin-event__date">{new Date(event.date).toLocaleDateString()}</p>
                <div className="admin-event__buttons">
                <Button 
                  Text="Modifier" 
                  Link="#" 
                  onClick={() => setEditingEvent(event)}
                />
                <Button 
                  Text="Supprimer" 
                  Link="#" 
                  onClick={() => handleDeleteEvent(event._id)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}